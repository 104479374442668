
const Release = (props) => {

  const OnClick = (event) => {
    event.preventDefault()
    window.location.href = props.link
  }

  return (
    <div className="bg-zinc-800 rounded-lg overflow-hidden hover:scale-110 focus:95 transition-all" onClick={OnClick}>
      <img src={props.src} alt={"Release-" + props.name} className="w-full aspect-square object-cover" />
      <div className="p-4">
        <h3 className="text-lg font-bold">{props.title}</h3>
        {/*<p className="text-gray-400">Release Date: </p>*/}
      </div>
    </div>
  )
}

export default Release
