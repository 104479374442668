const Artist = (props) => {
  return (
    <div className="flex flex-col items-center space-y-2">
      <img src={props.src} alt={props.name} className="rounded-full aspect-square object-cover h-48 w-48" />
      <h3 className="text-lg font-bold">{props.name}</h3>
      <a href={props.href} className="text-primary-500 px-4 py-2 rounded-lg bg-zinc-900 hover:underline hover:scale-110 transition-all focus:scale-95" prefetch={false}>
        Follow
      </a>
    </div>
  )
}

export default Artist
