import { Link, useNavigate } from "react-router-dom"

const Header = () => {
  let navigate = useNavigate()

  const goToHome = () => {
    navigate("/")
  }

  return (
    <header className=" container mx-auto py-8 px-4 md:px-6 flex items-center">
      <img
        src="/assets/logo_EJR.png"
        alt="Rhythm Records Logo"
        className="mx-8 w-12 h-12"
      />
      <p className="text-white text-2xl font-bold">Euro Jackson Records</p>
    </header>
  )
}

export default Header

