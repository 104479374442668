import Artist from './component/Artist';
import Form from './component/Form';
import Header from './component/Header'
import Release from './component/Release';

function App() {
  return (
    <div className="bg-zinc-900 text-white">
      <Header />

      <section className="container mx-auto py-16 px-4 md:px-6 flex flex-col md:flex-row items-center justify-between">
        <div className="space-y-4 md:w-1/2">
          <h1 className="text-4xl font-bold">Discover the Best in Rap</h1>
          <p className="text-gray-400">
            Euro Jackson Records is the leading label for the hottest new rap artists. <br />
            Explore our roster and find your new favorite.
          </p>
        </div>
        <img
          src="/assets/logo_EJR.png"
          alt="Rhythm Records Logo"
          className="mt-8 md:mt-0 md:w-1/2 aspect-square object-contain"
        />
      </section>

      <section className="bg-zinc-800 py-16 px-4 md:px-6">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-8">Nos artistes</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">

            <Artist name="2LaHauteCouture" src="./assets/2LA.jpg" href="https://www.instagram.com/2lahautecouture/" />
            <Artist name="44VY" src="./assets/44VY.jpg" href="https://www.instagram.com/savvyy44/" />
            <Artist name="Rizus" src="./assets/Rizus.jpg" href="https://www.instagram.com/rizusjackson/" />

          </div>
        </div>
      </section>

      <section className="container mx-auto py-16 px-4 md:px-6">
        <h2 className="text-3xl font-bold mb-8">Dernieres sorties</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">

          <Release title="YA HASRA" src="./assets/Release/2LA.jpg" link="https://www.youtube.com/watch?v=wDjKb2GoyU0" />
          <Release title="Du Temps" src="./assets/Release/44VY.jpg" link="https://www.youtube.com/watch?v=LD0J1zRKVhY" />

        </div>
      </section>

      <section className="mx-auto py-16 px-4 md:px-6">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-8">Contactez nous</h2>
          <Form />
        </div>
      </section>
    </div>
  );
}

export default App;
